import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <div className="w-screen min-h-screen flex justify-center items-center">
      <h1>Project is back dev mode</h1>
    </div>
  </React.StrictMode>
);
